.gallery {
  flex-direction: column;
  display: flex;
}

.img {
  align-self: center;
  max-width: 100vw;
}

.img-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-desc {
  font-size: var(--text-img);
  justify-self: start;
}

.play-overlay {
  cursor: pointer;
  background-image: url("play_button.fc644abb.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video {
  z-index: 1000;
  background-color: #000c;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.video iframe {
  width: 80%;
}

.gallery::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.gallery {
  scrollbar-width: none;
}

@media (width >= 1024px) {
  .gallery {
    overscroll-behavior: none;
    flex-flow: row;
    overflow-x: scroll;
  }

  .img {
    max-height: 400px;
  }

  .img-desc {
    padding-top: 10px;
    padding-left: 20px;
  }
}

@media (width >= 1360px) and (height >= 800px) {
  .img {
    max-height: 650px;
    max-width: initial;
  }
}
/*# sourceMappingURL=index.c80b85e6.css.map */
