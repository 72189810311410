/* Gallery */

.gallery {
    display: flex;
    flex-direction: column;
}

.img {
    align-self: center;
    max-width: 100vw;
}

.img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-desc {
    justify-self: start;
    font-size: var(--text-img);
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-image: url('../assets/img/ui/play_button.png');
    background-repeat: no-repeat;
    background-position: center;
}

.video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    justify-content: center;
    display: none;
    z-index: 1000;
}

.video iframe {
    width: 80%;
}

/* Chrome Scrollbar Hide */

.gallery::-webkit-scrollbar {
    width: 0;
    height: 0;
}

/* Mozilla Scrollbar Hide */
.gallery {
    scrollbar-width: none;
}

@media (min-width: 1024px) {
    .gallery {
        flex-direction: row;
        overflow-x: scroll;
        overscroll-behavior: none;
        flex-wrap: nowrap;
    }
    .img {
        max-height: 400px;
    }
    .img-desc {
        padding-left: 20px;
        padding-top: 10px;
    }
}

@media (min-width: 1360px) and (min-height: 800px) {
    .img {
        max-height: 650px;
        max-width: initial;
    }
}